import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function YouKu({ matches, ...props }: Props) {
  const videoId = matches[2];

  return (
    <Frame
      {...props}
      src={`https://player.youku.com/embed/${videoId}`}
      title={`YouKu (${videoId})`}
    />
  );
}

export default YouKu;
