import * as React from "react";

type Props = {
    uid: string;
    username: string;
    id?: string | number;
};

export default function AtComponent({ uid, username, ...rest }: Props) {
    return <span className="at" data-uid={uid} {...rest} >@{username}</span>;
}