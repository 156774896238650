import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function LanHu({ matches, ...props }: Props) {
  const videoId = matches[2];
  console.log('LanHu', matches, videoId)
  return (
    <Frame
      {...props}
      src={`https://lanhuapp.com/url/${videoId}`}
      title={`LanHu (${videoId})`}
    />
  );
}

export default LanHu;
