import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function WangYi({ matches, ...props }: Props) {
  const videoId = matches[3];
  // console.log('WangYi', matches, videoId)
  return (
    <Frame
      {...props}
      src={`https://music.163.com/outchain/player?type=2&id=${videoId}&auto=0&height=66`}
      title={`WangYi (${videoId})`}
      width="400px"
      height="90px"
    />
  );
}

export default WangYi;
