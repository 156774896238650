import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function XiGua({ matches, ...props }: Props) {
  const videoId = matches[2];
  console.log('XiGua', matches, videoId)
  return (
    <Frame
      {...props}
      src={`https://www.ixigua.com/iframe/${videoId}?autoplay=0`}
      referrerPolicy="unsafe-url"
      title={`XiGua (${videoId})`}
    />
  );
}

export default XiGua;
