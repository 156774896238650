import Token from "markdown-it/lib/token";
import { InputRule } from "prosemirror-inputrules";
import * as React from "react";
import { NodeSpec, Node as ProsemirrorNode, NodeType } from "prosemirror-model";
import { EditorState, TextSelection, Plugin } from "prosemirror-state";
import { MarkdownSerializerState } from "../lib/markdown/serializer";
// import { run } from "../plugins/BlockMenuTrigger";
import isInCode from "../queries/isInCode";
import atRule from "../rules/at";
import { Dispatch, EventType, ComponentProps } from "../types";
import Node from "./Node";
import AtComponent from "../components/At";
import Extension from "../lib/Extension";

const OPEN_REGEX = /(?:^|\s)@([\u4e00-\u9fa5_0-9a-zA-Z+-]+)?$/;
const CLOSE_REGEX = /(?:^|\s)@(([\u4e00-\u9fa5_0-9a-zA-Z+-]*\s+)|(\s+[\u4e00-\u9fa5_0-9a-zA-Z+-]+)|[^\u4e00-\u9fa5_0-9a-zA-Z+-]+)$/;
const LINK_INPUT_REGEX = /\[@([^[]+)]\(\/user\/(\S+)\)$/;
export default class At extends Node {
    get type() {
        return "node";
    }

    get name() {
        return "at";
    }

    get schema(): NodeSpec {
        return {
            attrs: {
                id: {
                    default: "",
                },
                uid: {
                    default: '123',
                },
                username: {
                    default: '',
                },
            },
            inline: true,
            content: "text*",
            marks: "",
            group: "inline",
            selectable: false,
            atom: true,
            parseDOM: [
                {
                    tag: "span.at",
                    preserveWhitespace: "full",
                    getAttrs: (dom: HTMLDivElement) => {
                        const txt = dom.innerText || ''
                        return {
                            id: dom.id,
                            username: txt.split('@').pop() || '',
                            uid: dom.dataset.uid,
                        }
                    },
                },
            ],
            toDOM: (node) => {
                // const text = window.document.createTextNode('@' + node.attrs.username);
                return [
                    "span",
                    {
                        id: node.attrs.id,
                        class: "at",
                        'data-uid': node.attrs.uid
                    },
                    `@${node.attrs.username}`
                ];
            },
            toPlainText: (node) => `@${node.attrs.username}`,
        };
    }

    get rulePlugins() {
        return [atRule];
    }

    get plugins() {
        return [
            new Plugin({
                props: {
                    handleClick: () => {
                        this.editor.events.emit(EventType.atMenuClose);
                        return false;
                    },
                    handleKeyDown: (view, event) => {
                        // Prosemirror input rules are not triggered on backspace, however
                        // we need them to be evaluted for the filter trigger to work
                        // correctly. This additional handler adds inputrules-like handling.
                        if (event.key === "Backspace" || event.key === '@') {
                            // timeout ensures that the delete has been handled by prosemirror
                            // and any characters removed, before we evaluate the rule.
                            // setTimeout(() => {
                            //     const { pos } = view.state.selection.$from;
                            //     return run(view, pos, pos, OPEN_REGEX, (state, match) => {
                            //         if (match) {
                            //             this.editor.events.emit(EventType.atMenuOpen, match[1]);
                            //         } else {
                            //             this.editor.events.emit(EventType.atMenuClose);
                            //         }
                            //         return null;
                            //     });
                            // });
                        }

                        // If the query is active and we're navigating the block menu then
                        // just ignore the key events in the editor itself until we're done
                        if (
                            event.key === "Enter" ||
                            event.key === "ArrowUp" ||
                            event.key === "ArrowDown" ||
                            event.key === "Tab"
                        ) {
                            const { pos } = view.state.selection.$from;

                            // return run(view, pos, pos, OPEN_REGEX, (state, match) => {
                            //     // just tell Prosemirror we handled it and not to do anything
                            //     return match ? true : null;
                            // });
                        }

                        return false;
                    },
                },
            }),
        ];
    }

    component({ node }: ComponentProps) {
        const { uid, username, id } = node.attrs;
        return <AtComponent uid={uid} username={username} id={id} />
    }

    commands({ type }: { type: NodeType }) {
        return (attrs: Record<string, string>) => (
            state: EditorState,
            dispatch: Dispatch
        ) => {
            const { selection } = state;
            const position =
                selection instanceof TextSelection
                    ? selection.$cursor?.pos
                    : selection.$to.pos;
            if (position === undefined) {
                return false;
            }
            const node = type.create({
                username: attrs.username,
                uid: attrs.id
            });
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
            return true;
        };
    }

    inputRules({ type }: { type: NodeType }): InputRule[] {
        // console.log('%c inputRules 222222222222222222', 'color:red')
        // console.log('inputrule type', type)
        return [
            new InputRule(LINK_INPUT_REGEX, (state, match, start, end) => {
                console.log('%c -----okay', 'color:red')
                const [okay, username, uid] = match;
                console.log(okay)
                console.log('match')
                console.log(match)
                const { tr } = state;
                if (okay) {
                    tr.replaceWith(
                        start - 1,
                        end,
                        type.create({
                            uid,
                            username
                        })
                    );
                }

                return tr;
            }),
            // main regex should match only:
            // :word
            new InputRule(OPEN_REGEX, (state, match) => {
                console.log('%cOPEN_REGEX', 'color:red')
                if (
                    match &&
                    state.selection.$from.parent.type.name === "paragraph" &&
                    !isInCode(state)
                ) {
                    this.editor.events.emit(EventType.atMenuOpen, match[1]);
                }
                return null;
            }),
            // invert regex should match some of these scenarios:
            // :<space>word
            // :<space>
            // :word<space>
            // :)
            new InputRule(CLOSE_REGEX, (state, match) => {
                console.log('%c CLOSE_REGEX', 'color:red')
                if (match) {
                    this.editor.events.emit(EventType.atMenuClose);
                }
                return null;
            }),
        ];
    }

    toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
        // console.log('%c toMarkdown 222222222222222222', 'color:red')
        state.write(
            `[@${node.attrs.username}](/user/${node.attrs.uid})`
        );
    }

    parseMarkdown() {
        // console.log('%c parseMarkdown 1111111111111111', 'color:red')
        return {
            node: "at",
            getAttrs: (tok: Token) => {
                return {
                    uid: tok.attrGet("uid"),
                    username: tok.attrGet("username")
                }
            },
        };
    }
}
