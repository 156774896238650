import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function UUke({ matches, ...props }: Props) {
  const videoId = matches[2];
  const isUUKeVideo = /http(.*)\.mp4/.test(props.attrs.href);

  console.log('isUUKeVideo', isUUKeVideo)
  console.log('href', props.attrs.href)
  return (
    <Frame
      {...props}
      src={`${props.attrs.href}`}
      isUUKeVideo={isUUKeVideo}
      referrerPolicy="unsafe-url"
      title={`UUke video)`}
    />
  );
}

export default UUke;
