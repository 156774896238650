import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function Modao({ matches, ...props }: Props) {
  const videoId = matches[2];

  return (
    <Frame
      {...props}
      src={`https://free.modao.cc/app/${videoId}/embed/v2`}
      title={`Modao (${videoId})`}
    />
  );
}

export default Modao;
