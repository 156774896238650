/*
 * @Author: Zhangyj
 * @Date: 2022-07-04 17:24:04
 * @LastEditors: Zhangyj
 * @LastEditTime: 2022-12-30 17:56:02
 * @Description: Do not edit
 */
import * as React from "react";
import styled from "styled-components";
import axios from 'axios';

const Preview = (props: { attachmentId: string | undefined, type: number, isOuterLink: boolean | undefined }) => {
    // const { attachmentId, type } = props.match.params;
    const { attachmentId, type, isOuterLink } = props;
    const [loading, setLoading] = React.useState(true);
    const [err, setError] = React.useState(false);
    const [href, setHref] = React.useState<string | undefined>('');
    const videoRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!attachmentId || !type) return;
        setLoading(true)
        getAttachment();
    }, [props.attachmentId, props.type])

    // console.log("isOuterLink", isOuterLink, attachmentId, type)
    async function getAttachment() {
        if (isOuterLink) {
            setHref(attachmentId);
            return;
        }
        const res = await axios.post(`/api/attachment.url`, {
            id: attachmentId
        });
        if (!res.data || res.status !== 200) return;
        setHref(res.data?.data?.url)
        setLoading(false)
    }

    function handleLoad() {
        setLoading(false)
    }

    function handleError(e) {
        console.log('------------ video or audio load error -----------')
        console.log(e)
        setError(true)
    }

    return (
        <>
            {
                href ?
                    (
                        type == 1 ? (
                            <AudioBox>
                                {/* {loading && <Loading height="100%">{err ? '资源加载出错了！' : '资源加载中...'}</Loading>} */}
                                <Audios
                                    controls
                                    onCanPlay={handleLoad}
                                    onError={handleError}
                                    controlslist="nodownload nofullscreen noremoteplayback">
                                    <source src={href} type="audio/mp3" />
                                </Audios>
                            </AudioBox>
                        ) : (
                            <VideoBox style={{ height: loading ? '300px' : 'auto' }}>
                                {loading && <Loading position="absolute">{err ? '资源加载出错了！' : '资源加载中...'}</Loading>}
                                <Videos
                                    controls
                                    ref={videoRef}
                                    data-type="embed-video"
                                    raw-controls="true"
                                    controlslist="nodownload noremoteplayback"
                                    poster=""
                                    disablepictureinpicture=""
                                    webkit-playsinline="true"
                                    playsinline=""
                                    onCanPlay={handleLoad}
                                    onError={handleError}
                                    className="videos"
                                    custom-cache={false}
                                    preload="metadata">
                                    <source src={href} type="video/mp4" />
                                </Videos>
                            </VideoBox>
                        )
                    ) :
                    <Loading position="static">资源加载中...</Loading>
            }
        </>
    )
};


const Loading = styled.div<{
    position: string;
    height?: string;
}>`
  position: ${(props) => props.position || 'absolute'};
  width: 100%;
  // height: 300px;
  height:  ${(props) => props.height || '300px'};
  display:flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${(props) => props.theme.audioBg};
  z-index: 5;
  font-size: 13px;
  color:  ${(props) => props.theme.textTertiary};
`

const AudioBox = styled.div`
  position: relative;
  height: auto;
  background: ${(props) => props.theme.audioBg};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0;
`
const VideoBox = styled.div`
  position: relative;
  height: auto;
  // background: ${(props) => props.theme.videoBg};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0;  
  overflow:hidden;
`

const Audios = styled.audio`
  width: 100%;
  height: 54px;  
  outline: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`

const Videos = styled.video`
  position: relative;
  zIndex: 1;
  background: rgb(0, 0, 0);
  outline: none;
  border-radius: 6px;
  max-height: 500px;
  max-width: 100%;
  border: 1px solid ${(props => props.theme.embedBorder)};
`

export default Preview;
