import { observable } from "mobx";
import { observer } from "mobx-react";
import { OpenIcon } from "outline-icons";
import * as React from "react";
import styled from "styled-components";
import { Optional } from "utility-types";
import { s } from "../../styles";
import { sanitizeUrl } from "../../utils/urls";

type Props = Omit<Optional<HTMLIFrameElement>, "children"> & {
  /** The URL to load in the iframe */
  src?: string;
  /** Whether to display a border, defaults to true */
  border?: boolean;
  /** The aria title of the frame */
  title?: string;
  /** An icon to display under the frame representing the service */
  icon?: React.ReactNode;
  /** The canonical URL of the content */
  canonicalUrl?: string;
  /** Whether the node is currently selected */
  isSelected?: boolean;
  /** The width of the frame */
  width?: string;
  /** The height of the frame */
  height?: string;
  /** The allow policy of the frame */
  allow?: string;
  /** Whether to skip sanitization of the `src` prop */
  dangerouslySkipSanitizeSrc?: boolean;
  dropHeight?: number | null;
  isUUKeVideo?: boolean;
};

type PropsWithRef = Props & {
  forwardedRef: React.Ref<HTMLIFrameElement>;
};

@observer
class Frame extends React.Component<PropsWithRef> {
  mounted: boolean;

  @observable
  isLoaded = false;

  @observable
  showMask = false;

  @observable
  lastHeight = 400;

  @observable
  custmerHeightNumber: number;

  @observable
  custmerHeight: string | number | undefined = '';

  componentDidMount() {
    this.mounted = true;
    this.custmerHeight = this.props.height || '400px';
    setTimeout(this.loadIframe, 0);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadIframe = () => {
    if (!this.mounted) {
      return;
    }
    this.isLoaded = true;
  };

  render() {
    const {
      border,
      width = "100%",
      height = "400px",
      forwardedRef,
      icon,
      title,
      canonicalUrl,
      isSelected,
      referrerPolicy,
      className = "",
      dangerouslySkipSanitizeSrc,
      src,
      isUUKeVideo,
    } = this.props;
    const withBar = !!(icon || canonicalUrl);

    // 鼠标按下
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const startPositionY = e.clientY;
      let endPositionY = 0;

      this.showMask = true;

      document.onmousemove = (c: MouseEvent) => {
        var c = event || window.event;
        endPositionY = c.offsetY;
        const change = c.clientY - startPositionY;

        this.custmerHeightNumber = this.lastHeight + change;
        this.custmerHeight = `${this.custmerHeightNumber}px`
      };

      document.onmouseup = () => {
        this.lastHeight = this.custmerHeightNumber;
        document.onmousemove = null;
        this.showMask = false;
      }
    }

    return (
      <Rounded
        width={width}
        height={this.custmerHeight as string}
        $withBar={withBar}
        $border={border}
        // className={
        //   isSelected ? `ProseMirror-selectednode ${className}` : className
        // }
        className={"ProseMirror-selectednode"}
        style={{ pointerEvents: this.showMask ? 'none' : 'initial' }}
      >
        {this.isLoaded && !isUUKeVideo && (
          <Iframe
            ref={forwardedRef}
            $withBar={withBar}
            // sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads allow-storage-access-by-user-activation"
            width={width}
            // height={height}
            height={'100%'}
            frameBorder="0"
            title="embed"
            loading="lazy"
            src={dangerouslySkipSanitizeSrc ? src : sanitizeUrl(src)}
            referrerPolicy={referrerPolicy}
            allowFullScreen
          />
        )}
        {this.isLoaded && isUUKeVideo && (
          <video controls src={src} width={'100%'} height={'100%'} />
        )}
        {withBar && (
          <Bar>
            {icon} <Title>{title}</Title>
            {canonicalUrl && (
              <Open
                href={canonicalUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenIcon size={18} /> Open
              </Open>
            )}
          </Bar>
        )}
        <DropTrigger onMouseDown={onMouseDown}></DropTrigger>
      </Rounded>
    );
  }
}

const Iframe = styled.iframe<{ $withBar: boolean }>`
  border-radius: ${(props) => (props.$withBar ? "3px 3px 0 0" : "3px")};
  display: block;
`;

const Rounded = styled.div<{
  width: string;
  height: string;
  $withBar: boolean;
  $border?: boolean;
}>`
  position: relative;
  border: 1px solid
    ${(props) => (props.$border ? props.theme.embedBorder : "transparent")};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width};
  height: ${(props) => (props.$withBar ? props.height + 28 : props.height)};
`;

const Open = styled.a`
  color: ${s("textSecondary")} !important;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  padding: 0 8px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: 500;
  padding-left: 4px;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.embedBorder};
  background: ${s("secondaryBackground")};
  color: ${s("textSecondary")};
  padding: 0 8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  user-select: none;
  position: relative;
`;

/**
 * Resize observer script that sends a message to the parent window when content is resized. Inject
 * this script into the iframe to receive resize events.
 */
export const resizeObserverScript = `<script>
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      window.parent.postMessage({ "type": "frame-resized", "value": entry.contentRect.height }, '*');
    }
  });
  resizeObserver.observe(document.body);
</script>`;

const DropTrigger = styled.div`
  width: 56px;
  height: 8px;
  background-color: #D8DAD9;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -28px;
  z-index: 30;
  cursor: row-resize;
}
`;

export default React.forwardRef<HTMLIFrameElement, Props>((props, ref) => (
  <Frame {...props} forwardedRef={ref} />
));
