import * as React from "react";
// import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";
import styled from 'styled-components';

function WebOffice({ matches, ...props }: Props) {
    const href = props.attrs.href;

    return (
        <Iframe
            $withBar={false}
            width="100%"
            frameBorder="0"
            title="embed"
            loading="lazy"
            // src={`/preview/${encodeURIComponent(href)}`}
            src={`${href}`}
            referrerPolicy="unsafe-url"
            allowFullScreen
        />
    );
}

const Iframe = styled.iframe<{ $withBar: boolean }>`
    border-radius: ${(props) => (props.$withBar ? "3px 3px 0 0" : "3px")};
    display: block;
    position: relative;
    border: 1px solid #DAE1E9;
    border-radius: 6px;
    width: 100%;
    height: 400px;
`;

export default WebOffice;
