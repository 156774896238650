import Token from "markdown-it/lib/token";
import MarkdownIt from "markdown-it";


function isParagraph(token: Token) {
    return token.type === "paragraph_open";
}

function isInline(token: Token) {
    return token.type === "inline";
}

function isLinkOpen(token: Token) {
    return token.type === "link_open";
}

function isLinkClose(token: Token) {
    return token.type === "link_close";
}
function isText(token: Token) {
    return token.type === "text";
}

function isAt(token: Token) {
    const href = token.attrGet("href");
    return (
        // internal
        href?.startsWith("/user/")
    );
}

export default function at(md: MarkdownIt) {
    md.renderer.rules.at = function at_html(tokens, idx /*, options, env */) {
        return tokens[idx].content;
    };
    md.core.ruler.after("inline", "at", (state) => {
        const tokens = state.tokens;
        let insideLink;
        for (let i = 0; i < tokens.length - 1; i++) {
            if (isInline(tokens[i]) && isParagraph(tokens[i - 1])) {
                let tokenChildren = tokens[i].children || [];
                for (let j = 0; j < tokenChildren.length - 1; j++) {
                    const current = tokenChildren[j];
                    if (!current) {
                        continue;
                    }
                    if (isLinkOpen(current)) {
                        insideLink = current;
                        continue;
                    }
                    if (isLinkClose(current)) {
                        insideLink = null;
                        continue;
                    }
                    if (insideLink && isAt(insideLink) && isText(current)) {
                        const { content } = current;
                        const username = content.split('@').pop()
                        const token = new Token("at", "span", 0);
                        const href = insideLink.attrGet("href") || '';
                        const uid = href.split('/').pop();
                        token.attrSet("uid", uid);
                        token.attrSet("username", username);
                        token.content = '@' + username;
                        tokenChildren.splice(j - 1, 3, token);
                        tokens[i].children = tokenChildren;
                        // console.log('At tokens', tokenChildren)
                        insideLink = null;
                        break;
                    }
                }
            }
        }
        return false;
    })
}
