import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { Optional } from "utility-types";
import { EyeIcon, DownloadIcon } from "outline-icons";
import axios from 'axios';

type Props = Omit<Optional<HTMLIFrameElement>, "children"> & {
    border?: boolean;
    title?: string;
    icon?: React.ReactNode;
    canonicalUrl?: string;
    isSelected?: boolean;
    width?: string;
    height?: string;
    allow?: string;
    dropHeight?: number | null;

    context?: React.ReactNode;
    href?: string;
};

type PropsWithRef = Props & {
    forwardedRef: React.Ref<HTMLIFrameElement>;
};

@observer
class Frame extends React.Component<PropsWithRef> {
    mounted: boolean;

    @observable
    isLoaded = false;

    @observable
    loading = false;

    @observable
    previewUrl = '';

    @observable
    showMask = false;

    @observable
    lastHeight = 500;

    @observable
    custmerHeightNumber: number;

    @observable
    custmerHeight: string | number | undefined = '';

    componentDidMount() {
        this.mounted = true;
        this.custmerHeight = this.props.height || '500px';
        setTimeout(() => {
            this.loadIframe()
        }, 0);
        this.getInfo(this.props.href);
    }

    componentWillUpdate(nextProps) {
        if (nextProps.href != this.props.href) {
            this.getInfo(nextProps.href)
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadIframe = () => {
        if (!this.mounted) {
            return;
        }
        this.isLoaded = true;
    };

    async getInfo(href?: string) {
        const docId = href ? href?.split('?id=')[1] : this.props.href?.split('?id=')[1];
        if (!docId) {
            this.previewUrl = '';
            return;
        }
        this.loading = true;
        const res = await axios.post(`/api/attachment.info`, {
            id: docId
        });
        this.loading = false;
        if (!res.data || res.status !== 200) return;
        const data = res.data.data;
        this.previewUrl = data.preview_url || data.url;
    }

    render() {
        let dropHeight: number | string | null = 0;
        const {
            border,
            width = "100%",
            height = "500px",
            forwardedRef,
            icon,
            title,
            canonicalUrl,
            isSelected,
            referrerPolicy,
            href
        } = this.props;

        // /api/attachments.redirect?id=e0b288d9-68ea-488c-805e-d1125bd02047
        const docId = href?.split('?id=')[1];

        const withBar = !!(icon || canonicalUrl);

        // 鼠标按下
        const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const startPositionY = e.clientY;
            let endPositionY = 0;

            this.showMask = true;

            document.onmousemove = (c: MouseEvent) => {
                var c = event || window.event;
                endPositionY = c.offsetY;
                const change = c.clientY - startPositionY;

                this.custmerHeightNumber = this.lastHeight + change;
                this.custmerHeight = `${this.custmerHeightNumber}px`
            };

            document.onmouseup = () => {
                this.lastHeight = this.custmerHeightNumber;
                document.onmousemove = null;
                this.showMask = false;
            }
        }

        return (
            <Rounded
                $withBar={withBar}
                $border={border}
                className={"ProseMirror-selectednode"}
                style={{
                    pointerEvents: this.showMask ? 'none' : 'initial',
                    outline: this.previewUrl ? '2px solid #0366d6' : 'none',
                    height: this.previewUrl ? this.custmerHeight : 'auto',
                    width
                }}
            >
                {withBar && (
                    <Bar style={{
                        borderLeft: this.previewUrl ? 'none' : '1px solid #DAE1E9',
                        borderRight: this.previewUrl ? 'none' : '1px solid #DAE1E9',
                    }}>
                        <div>
                            {icon} <Title>{title}</Title>
                            <Subtitle>{this.props.context}</Subtitle>
                        </div>
                        <Children>
                            {
                                href &&
                                <>
                                    <Link target="_blank" href={`/preview/${docId}`} rel="noreferrer nofollow">
                                        {/* <IconBox> */}
                                        <EyeIcon color="currentColor" size={20} />
                                        {/* </IconBox> */}
                                    </Link>
                                    <Link target="_blank" href={href} rel="noreferrer nofollow">
                                        <DownloadIcon color="currentColor" size={20} />
                                    </Link>
                                </>
                            }
                            {this.props.children}
                        </Children>
                    </Bar>
                )
                }

                {
                    !this.loading && this.isLoaded && this.previewUrl && (
                        <>
                            <Iframe
                                ref={forwardedRef}
                                $withBar={withBar}
                                // sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
                                width={width}
                                frameBorder="0"
                                title="embed"
                                loading="lazy"
                                // src={`/preview/${docId}`}
                                src={this.previewUrl}
                                referrerPolicy={referrerPolicy}
                                allowFullScreen
                            />
                            <DropTrigger onMouseDown={onMouseDown}></DropTrigger>
                        </>
                    )
                }
            </Rounded >
        );
    }
}

const Iframe = styled.iframe<{ $withBar: boolean }>`
  border-radius: ${(props) => (props.$withBar ? "3px 3px 0 0" : "3px")};
  display: block;
  height: calc(100% - 50px);
`;

const Rounded = styled.div<{
    width: string;
    height: string;
    $withBar: boolean;
    $border?: boolean;
}>`
  position: relative;
  border: 1px solid
    ${(props) => (props.$border ? props.theme.embedBorder : "transparent")};
  border-radius: 6px;
  width: ${(props) => props.width};
  height: ${(props) => (props.$withBar ? props.height + 28 : props.height)};
`;

const Open = styled.a`
  color: ${(props) => props.theme.textSecondary} !important;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  padding: 0 8px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: 500;
  padding-left: 4px;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.embedBorder};
  // background: ${(props) => props.theme.secondaryBackground};
  background: #FFFFFF;
  color: ${(props) => props.theme.textSecondary};
  padding: 0 8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  user-select: none;
  height: 50px;
  border-bottom: 1px solid #D8DAD9;
`;
const DropTrigger = styled.div`
  width: 56px;
  height: 8px;
  background-color: #D8DAD9;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -28px;
  z-index: 30;
  cursor: row-resize;
}
`;

const Subtitle = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.textTertiary} !important;
  line-height: 0;
  margin-left: 8px;
`;

const Children = styled.div`
  margin-left: auto;
  // height: 20px;
  cursor: pointer;
  // opacity: 0;

  &:hover {
    color: ${(props) => props.theme.text};
  }
`;

const IconBox = styled.span`
  display: inline-block;
  width: 30px;
  height:30px;
  line-height: 30px;
  text-align: center;
  padding-top: 5px;
  &:hover {
    background-color: #F4F5F5;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.textSecondary} !important;
  margin-right: 5px;
  display: inline-block;
  width: 30px;
  height:30px;
  line-height: 30px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  &:hover {
    background-color: #F4F5F5;
  }
`

export default React.forwardRef<HTMLIFrameElement, Props>((props, ref) => (
    <Frame {...props} forwardedRef={ref} />
));
