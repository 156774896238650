import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function OaAttachema({ matches, ...props }: Props) {
    const href = props.attrs.href;

    return (
        <Frame
            {...props}
            height="500px"
            src={href}
            title={`OA 预览`}
        />
    );
}

export default OaAttachema;
